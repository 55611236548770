$black-color: #282c34;
$blue-color: #61dafb;
$white-color: #ffffff;

.App {
  background: #FFFFFF;

  .App-logo {
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 62px;
    /* identical to box height */

    color: #008FF7;
    margin-top: 10%;
  }

  .rskLogo {
    height: 75px;
  }

  .marginNextStep {
    margin-bottom: 5%;
  }

  .bullet {
    font-size: 16px;
    text-align: center;
    color: #008FF7;

    border: 1px solid #008FF7;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 5px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: -5px;
  }

  .headerAdress {
    text-align: center;
    color: #008FF7;

    border: 1px solid #008FF7;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding: 1% 2% 1% 2%;
  }

  .headerNet {
    text-align: center;
    font-weight: normal;
    text-decoration-color: #FFFFFF;
    color: #FFFFFF;

    border: 1px solid #FFFFFF;
    border-radius: 30px;
    background-color: #008FF7;
    padding: 1% 2% 1% 2%;
    margin-right: 1%;
    margin-left: 1%;
  }

  .headerDropdown {
    margin-right: 10%;

    .dropdown-menu {
      right: 0 !important;
    }
  }

  .centerDivButtons { text-align: center; }

  .button {
    color: #008FF7;
    text-align: center;

    border: 1px solid #008FF7;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding: 1% 10% 1% 10%;

    opacity: 0.9;
    transition: 0.3s;
    text-decoration: none;
  }

  button:disabled,
  button[disabled] {
    text-align: center;
    color: grey;

    border: 1px solid grey;
    border-radius: 30px;
    background-color: #FFFFFF;
    opacity: 0.9;
    transition: 0.3s;
  }

  button:disabled:hover,
  button[disabled]:hover{
    text-align: center;
    color: grey;

    border: 1px solid grey;
    border-radius: 30px;
    background-color: #FFFFFF;
    opacity: 0.9;
    transition: 0.3s;
  }

  .button:hover {
    background-color: #008FF7;
    font-weight: 400;
    color: white;
    box-shadow: 5px 5px 2px grey;
  }
  .bottom-column{
    padding: 5px;

  }
  .buttonAddToken {
    font-family: Quicksand;
    font-style: normal;
    font-size: 16px;
    text-align: center;
    color: #008FF7;
    float: left;
    border: 1px solid #008FF7;
    box-sizing: border-box;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding:10px ;
    width: 100%;
    height: 70px;
    opacity: 1;
    transition: 0.4s;
  }
  .tokens-bottom-row{
    margin-top: 20px;
  }

  .buttonAddToken:hover {
    background-color: #008FF7;
    font-weight: 400;
    color: white;
    box-shadow: 5px 5px 2px grey;
  }

  .buttonAddRIF {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #008FF7;

    border: 1px solid #008FF7;
    box-sizing: border-box;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding: 1% 10% 1% 10% ;
  }

  .buttonAddDOC {
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #008FF7;

    border: 1px solid #008FF7;
    box-sizing: border-box;
    border-radius: 30px;
    background-color: #FFFFFF;
    padding: 1% 10% 1% 10%;
  }

  .buttonAddMoreTokens {
    text-align: center;
  }

  .mainImage {
    border-radius: 50%;
    margin-left: 25%;
    margin-top: 25%;
    background: url('./img/bitcoinHand.png');
    position: relative;

  }

  #mainImageCircle {
    border-radius: 50%;
    margin-top: 40%;
    background: #188FFC;
    width: 100px;
    height: 100px;
  }

  .toolExplanation {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
    margin-bottom: 10%;
  }

  .step {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    display: block;
    margin-bottom: 20px;
    align-items: center;
    text-align: left;

    color: #000000;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
  }


  /* =================FOOTER =================*/
#footer {
  font-size: 16px;
  font-weight: 300;
  position: relative;
  z-index: 1000;
  margin-top: 15rem;
}

.powered_by {
  width: 250px;
  height: auto;
  margin: 20px 0 30px 0;
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: black;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info p {
  line-height: 28px;
  margin-bottom: 0;
  color: white;
}

#footer .footer-top .social-links {
  margin-top: 30px;
  float: right;
}

#footer .footer-top .social-links a.twitter {
  font-size: 15px;
  display: inline-block;
  background: #008CFF;
  color: white;
  line-height: 1;
  padding: 6px 0;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  width: 26px;
  height: 26px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover.twitter {
  background: white;
  color: black;
}

#footer .footer-top .social-links a.telegram {
  font-size: 26px;
  display: inline-block;
  color: #008CFF;
  line-height: 1;
  padding: 6px 0;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  width: 26px;
  height: 26px;
  transition: 0.3s;
  position: relative;
  top: 4px;
}

#footer .footer-top .social-links a:hover.telegram {
  color: black;
}

.fa-telegram {
  background: white;
  border-radius: 20px;
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-twitter:before {
  content: "\f099";
}

*, ::after, ::before {
  box-sizing: border-box;
}

#footer .footer-top h4 {
  font-size: 14px;
  font-weight: bold;
  color: #413e66;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul li {
  padding: 6px 0;
}

#footer .footer-top .footer-links ul li:first-child {
  color: white;
}

#footer .footer-top .footer-links ul a {
  color: white;
  text-decoration: none;
}

#footer .footer-top .footer-links ul li a {
  line-height: 20px;
  display: block;
}

#footer .footer-top .footer-links ul li a.medium {
  font-weight: 700;
}

#footer .footer-top .footer-links ul a:hover {
  color: white;
  text-decoration: none;
}

#footer .footer-top .footer-links .sub-item-footer {
  padding: 0 0 0px 15px !important;
  font-style: italic;
  margin-bottom: 6px;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: left;
  padding-top: 30px;
  color: white;
  font-size: 12px;
  padding: 0px;

}

#footer .credits {
  text-align: right;
  font-size: 12px;
  padding-top: 4px;
  color: #00B520;
  padding: 0px;
  margin: 30px 0px 0px 0px;
}

#footer .credits a {
  color: #1bb1dc;
}

#footer .credits a:hover {
  color: #0a98c0;
}

@media (max-width: 991px) {
  #footer .footer-top .social-links {
      float: left;
  }

  #footer .footer-top .social-links {
      float: left;
  }

  .pl-0-mobile {
      padding-left: 0px;
  }

  #footer .footer-top {
      padding: 60px 15px 30px 15px;
  }

  #footer .footer-top .social-links a {
      margin-bottom: 10px;
  }

  #footer .footer-top .footer-links {
      margin-bottom: inherit;
  }

  #footer .credits {
      text-align: left;
  }

}

  // mobile styles
  @media (max-width: 767.98px) {
    footer {
      margin-top: -75px;

      .footer-top {
        padding-top: 0 !important;
      }
    }
  }

  .supported-exchanges {
    h2 {
      text-align: center;
    }

    .exchange-carousel {
      padding-bottom: 50px;

      .carousel-control-prev, .carousel-control-next {
        width: auto;
        background-image:none;
      }

      .carousel-control-next-icon:after {
        content: '>';
        font-size: 45px;
        color:  #008FF7;
      }

      .carousel-control-prev-icon:after {
        content: '<';
        font-size: 45px;
        color:  #008FF7;
      }

      .carousel-indicators li {
        background-color:  #008FF7;
      }

      .carousel-inner {
        a { 
          display: block;

          p {
            text-align: center;
          }

          .image-container {
            display: block;
            margin: 0 auto;
            width: 120px;
            height: 180px;
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            opacity: .5;
          }
        }

        a {
          color: grey;
          text-decoration: none;
        }

        a:hover {
          p {
            color: #008FF7;
          }

          .image-container {
            opacity: 1;
          }
        }
      }
    }
  }

}
